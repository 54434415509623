<template>
  <el-row>
    <el-checkbox-group v-model="checked" @change="handleCheckedChange">
      <el-col :xs="24" :sm="8" :md="6" :lg="4.8" :xl="4" v-for="(o,index) in list" :key="index">
        <el-row class="pd5">
          <el-col :span="24">
            <el-card class="custom-card">
              <div slot="header" @click="cardClick(o)">
                <span :title="o.houseNo" class="title-left">{{o.houseNo}}</span>
                <span :title="o.liangVarieties||'-'" class="fr title-right">粮食品种：{{o.liangVarieties||'-'}}</span>
              </div>
              <div @click="cardClick(o)" class="testing-body">
                <div v-if="checkbox" class="testing-body-checkbox" @click.stop="">
                  <el-checkbox :label="o.locaId"></el-checkbox>
                </div>
                <div class="testing">
                  <div class="left-img">
                    <img :src="require('../assets/img/home/'+(o.cangType == 0?'wareHouse':'silo')+'.png')">
                  </div>
                  <div class="testing-content">
                    <p>仓温：{{ o.storehouseTemp }}℃</p>
                    <p>仓湿：{{ o.storehouseDampness }}℃</p>
                    <p>高温：{{ o.highestTemp }}℃</p>
                    <p>低温：{{ o.minimumTemp }}℃</p>
                    <p>均温：{{ o.averageTemp }}℃</p>
                  </div>
                </div>
                <p class="testing-time">检测时间：{{ o.tempTime }}</p>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </el-col>
    </el-checkbox-group>
  </el-row>
</template>

<script>
  export default {
    name: "home",
    props: {
      checkbox: {
        type: Boolean,
        default: false
      },
    },
    data() {
      return {
        list: [],
        checked: []
      }
    },
    created() {
      this.lastTesting()
    },
    methods: {
      //获取数据
      lastTesting() {
        this.$axios.Get(this.$api.LastTesting, {}).then(res => {
          this.list = res.data
        })
      },
      //看片点击事件
      cardClick(e) {
        if (!e.testingId) return
        this.$router.push({
          path: e.cangType === 0 ? '/solid/Ware' : '/solid/silo',
          query: {
            id: e.testingId,
            houseNoId: e.locaId,
            time: e.tempTime
          }
        })
      },
      handleCheckedChange() {
        console.log(this.checked)
      },
      getChecked() {
        return this.checked
      },
      clearChecked() {
        this.checked = [];
      },
      getAllChecked() {
        let ids = [];
        this.list.forEach(item => {
          ids.push(item.locaId)
        })
        this.checked = ids
        return ids
      }
    }
  }
</script>

<style scoped lang="less">
  .testing-body {
    position: relative;

    &-checkbox {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;

      /deep/ .el-checkbox__label {
        display: none;
      }
    }
  }


  .title-left {
    width: 50%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    float: left;
  }

  .title-right {
    width: 50%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: right;
    font-size: 12px;
  }

  .testing {
    position: relative;

    .left-img {
      width: 120px;
      height: 130px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    &-content {
      position: absolute;
      right: 0;
      top: 0;
      margin-left: 12px;
      font-size: 12px;

      p {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      p+p {
        margin-top: 10px;
      }
    }
  }

  /deep/ .custom-card .el-card__header {
    line-height: 15px;
    height: 15px;
    box-sizing: content-box;
  }

  .testing-time {
    font-size: 13px;
    margin-top: 10px;
    overflow: hidden;
    white-space: nowrap;

    text-overflow: ellipsis;
  }
</style>
